import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Drawer, MenuItem, IconButton, Typography, Divider} from 'material-ui';
import Collapse from 'material-ui/transitions/Collapse';
import {withStyles} from 'material-ui/styles';
import classNames from 'classnames';
import ChevronLeftIcon from 'material-ui-icons/ChevronLeft';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import Icon from 'material-ui/Icon';

const drawerWidth = 240;

const styles = theme => ({
  hide: {
    display: 'none'
  },
  drawerPaper: {
    position: 'relative',
    minHeight: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 15px',
    height: 56
  },
  drawerHeaderTitle: {
    flex: 1
  },
  menuUl:{
    margin:0,
    padding:0
  },
  menuLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    display: 'flex',
    flex: 1
  },
  subMenu:{
    background: theme.palette.grey[200]
  },
  icon: {
    marginRight: 10
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  textFlex: {
    flex: 1
  }
  ,
  [theme.breakpoints.up('sm')]: {
    drawerHeader: {
      height: 64
    }
  }
});

const menu = [{
  url: '/dashboard/',
  title: 'Bảng điều khiển',
  icon: 'dashboard'
}, {
  url: '/album/',
  title: 'Album Hình',
  icon: 'photo_album'
},{
  url: '/products/',
  title: 'Sản phẩm',
  icon: 'work_outline',
  subs: [
    {url:'/category/',title:'Nhóm sản phẩm',icon:'kitchen'},
    {url:'/product/',title:'Sản phẩm',icon:'card_travel'},
    {url: '/property/',title: 'Thuộc tính',icon: 'line_style'}
]},{
  url: '/school/',
  title: 'Đào tạo',
  icon: 'school',
  subs: [
    {url:'/course/',title:'Khóa học',icon:'book'},
    {url:'/calendar/',title:'Lịch khai giảng',icon:'calendar_today'},
    {url:'/lecturers/',title:'Giảng viên',icon:'contacts'},
    {url:'/document/',title:'Tài liệu',icon:'notes'},
]}, {
  url: '/service/',
  title: 'Dịch vụ',
  icon: 'star',
  subs: [
    {url:'/service/',title:'Dịch vụ',icon:'star'},
    {url:'/booking/',title:'Lịch đặt dịch vụ',icon:'calendar_view_day'}
]}, {
  url: '/bill/',
  title: 'Hóa đơn',
  icon: 'store'
}, {
  url: '/customer/',
  title: 'Tài khoản - Khách hàng',
  icon: 'face'
},/*  {
  url: '/report/',
  title: 'Báo cáo',
  icon: 'assessment'
}, */ {
  url: '/contents/',
  title: 'Nội dung',
  icon: 'note_add',
  subs: [
    {url: '/banner/',title: 'Banner',icon: 'photo'},
    {url:'/menu/',title:'Menu',icon:'menu'},
    {url: '/pages/',title: 'Nội dung',icon: 'note_add'},
    {url: '/testimonial/',title: 'Nhận xét',icon: 'record_voice_over'}
]}, {
  url: '/news/',
  title: 'Tin tức',
  icon: 'language',
  subs: [
    {url: '/news/',title: 'Tin tức',icon: 'language'},
    {url:'/newscat/',title:'Nhóm tin',icon:'kitchen'},
    /* {url: '/comments/',title: 'Thảo luận',icon: 'comment'} */
]}, {
  url: '/faqs/',
  title: 'FAQ',
  icon: 'question_answer',
  subs: [
    {url: '/faqgroup/',title: 'FAQ Group',icon: 'tab'},
    {url: '/faq/',title: 'FAQ',icon: 'question_answer'}
]},{
  url: '/controls/',
  title: 'Quản lý',
  icon: 'card_travel',
  subs: [
    {url: '/users/',title: 'Tài khoản',icon: 'account_circle'},
    {url:'/group/',title:'Nhóm',icon:'group'},
    {url: '/subscribes/',title: 'Subscribes',icon: 'record_voice_over'},
    {url: '/campaign/',title: 'Campaign',icon: 'local_post_office'},
    /* {url: '/permission/',title: 'Phân quyền',icon: 'warning'} */
]}, {
  url: '/media/',
  title: 'File Manager',
  icon: 'perm_media'
}, {
  url: '/setting/',
  title: 'Cấu hình',
  icon: 'settings'
}];

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      expanded: menu.map((item,index)=>{
        if(item.subs!==undefined){
          return false;
        }
      })
    }
  }
  handleExpandClick(event,index) {
    event.preventDefault();
    let arr = this.state.expanded;
    arr[index] = !arr[index];
    this.setState({ selectedIndex: index,expanded: arr },function(){
      
    });
    //this.props.setTitle(title);
  }
  componentDidMount() {
    var p = this.context.router.route.location.pathname,
        path = p.split('/')[1];
    this.changeMenuStatus(path);
  }
  changeMenuStatus(path){
    var page = path,
        $this = this;
    //console.log(page);
    switch (page) {
      case 'dashboard':
        this.setState({selectedIndex: 0,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      case 'album':
        this.setState({selectedIndex: 1,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break; 
      case 'product':
      case 'category':
      case 'property':
        this.setState({selectedIndex: 2,expanded:menu.map((item,index)=>{
          if(index==2){
            return true;
          }
        })});
        break;
      case 'course':
      case 'document':
      case 'calendar':
      case 'lecturers':
      case 'students':
        this.setState({selectedIndex: 3,expanded:menu.map((item,index)=>{
          if(index==3){
            return true;
          }
        })});
        break;
      case 'service':
      case 'booking':
        this.setState({selectedIndex: 4,expanded:menu.map((item,index)=>{
          if(index==4){
            return true;
          }
        })});
        break;
      case 'bill':
        this.setState({selectedIndex: 5,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      case 'customer':
        this.setState({selectedIndex: 6,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false
          }
        })});
        break;
      /* case 'report':
        this.setState({selectedIndex: 5,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break; */
      case 'banner':
      case 'menu':
      case 'pages':
        this.setState({
          selectedIndex: 7,
          expanded:menu.map((item,index)=>{
            if(index==7){
              return true;
            }
          })
        });
        break;
      case 'news':
      case 'newscat':
        this.setState({selectedIndex: 8,expanded: menu.map((item,index)=>{
          if(index==8){
            return true;
          }
        })},function(){
         // console.log($this.state);
        });
        break;
      case 'faq':
      case 'faqgroup':
        this.setState({selectedIndex: 9,expanded: menu.map((item,index)=>{
          if(index==9){
            return true;
          }
        })});
        break;
      case 'users':
      case 'group':
      case 'subscribes':
        this.setState({selectedIndex: 10,expanded:menu.map((item,index)=>{
          if(index==10){
            return true;
          }
        })});
        break;
      case 'media':
        this.setState({selectedIndex: 11,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      case 'setting':
        this.setState({selectedIndex: 12,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;  
      default:
        this.setState({selectedIndex: 0,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
    }
  }
  handleClose() {
    this.props.closeMenu();
  }
  setTitle(event,target, title, index) {
    if(this.state.selectedIndex===index){
      event.preventDefault();
      return;
    }
    if(index!==null)this.setState({selectedIndex: index});
    this.props.setTitle(title);
    this.changeMenuStatus(target.split('/')[1]);
  }
  render() {
    const classes = this.props.classes;
    var type = this.props.screenWidth < 960
      ? 'persistent'
      : 'permanent';
    
    return (
      <Drawer variant='persistent' open={this.props.open} classes={{
        paper: classNames(classes.drawerPaper, !this.props.isLoggedIn && classes.hide)
      }}>
        <div className={classes.drawerInner}>
          <div className={classes.drawerHeader}>
            <Typography className={classes.drawerHeaderTitle} type='title'>Suhion Admin</Typography>
            <IconButton onClick={() => this.handleClose()} color="primary" className={classNames('btn-close-menu')}><ChevronLeftIcon/></IconButton>
          </div>
          <Divider/>
          <ul className='menuUl'>
          {menu.map((item, index) => (
            <MenuItem key={index} selected={index === this.state.selectedIndex}>
              {item.subs!==undefined?(
                <div className={classes.menuWrapper}>
                  <Link className={classes.menuLink} to={item.url} onClick={(event)=>this.handleExpandClick(event,index)}>
                    <Icon className={classes.icon}>{item.icon}</Icon>
                    <Typography variant='body1' className={classes.textFlex}>{item.title}</Typography>
                    <ExpandMoreIcon className={classNames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded[index],
                    })} />
                  </Link>
                  <Collapse in={this.state.expanded[index]} timeout="auto" unmountOnExit className={classes.subMenu}>
                  {
                    item.subs.map((n,i)=>(
                      <Link className={classes.menuLink} to={n.url} key={'sub_'+i} onClick={event => this.setTitle(event,n.url,n.title, null)}>
                        <Icon className={classes.icon}>{n.icon}</Icon>
                        <Typography variant='body1' className={classes.textFlex}>{n.title}</Typography>
                      </Link>
                    ))
                  }
                  </Collapse>
                </div>
              ):(
                <Link className={classes.menuLink} to={item.url} onClick={event => this.setTitle(event,item.url,item.title, index)}>
                <Icon className={classes.icon}>{item.icon}</Icon>
                <Typography variant='body1' className={classes.textFlex}>{item.title}</Typography>
                </Link>
              )}
            </MenuItem>
          ))}
          </ul>
        </div>
      </Drawer>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired
};
Menu.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(Menu);
