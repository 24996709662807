import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import {styles} from './theme';

class Footer extends Component {
  render(){
    const { classes } = this.props;
    return(
      <footer className={classes.footer}>
      </footer>
    );
  }
}
Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Footer);
