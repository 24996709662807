import React, { Component } from 'react';
//import injectTapEventPlugin from 'react-tap-event-plugin';
import injectTapEventPlugin from './plugins/TapEventPlugin';
import { MuiThemeProvider } from 'material-ui/styles';
import 'typeface-roboto';

import theme from './layout/theme';
import Main from './layout/main.jsx';
import Header from './layout/header.jsx';
import Footer from './layout/footer.jsx';
import Menu from './layout/menu.jsx';

injectTapEventPlugin();

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      openMenu:true,
      title:'Suhion CMS',
      width:960,
      user: (localStorage.getItem('user')!==undefined?JSON.parse(localStorage.getItem('user')):{}),
      isLoggedIn: (localStorage.getItem('user_token') !== undefined&&localStorage.getItem('user_token')!==null)};
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth});
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  toggleMenu(){
    this.setState({openMenu: !this.state.openMenu});
  }
  handleClose() {
    console.log('action close');
    this.setState({openMenu: false});
  }
  updateTilte(text){
    this.setState({title: text});
  }
  loggedIn(){
    var logged = (localStorage.getItem('user_token') !== undefined&&localStorage.getItem('user_token')!==null);
    this.setState({isLoggedIn:logged,user:JSON.parse(localStorage.getItem('user'))},function(){
      
    });
  }
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="merncms">
          <Header screenWidth={this.state.width} user={this.state.user} open={this.state.openMenu} title={this.state.title} toggleMenu={()=>this.toggleMenu()} isLoggedIn={this.state.isLoggedIn} updateLoggedStatus={()=>this.loggedIn()}/>
          <div className="merncms-main-body">
            <Menu screenWidth={this.state.width} open={this.state.openMenu} theme={theme} closeMenu={()=>this.handleClose()} setTitle={(text)=>this.updateTilte(text)} isLoggedIn={this.state.isLoggedIn} user={this.state.user}/>
            <Main screenWidth={this.state.width} open={this.state.openMenu} isLoggedIn={this.state.isLoggedIn} updateLoggedStatus={()=>this.loggedIn()} user={this.state.user}/>
          </div>
          <Footer screenWidth={this.state.width}/>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
