const WebConfig = {
  development: {
    api: 'http://localhost:3004',
    image: 'http://localhost:3004/data/images/',
    itemPerPage: 10,
  },
  staging: {
    api: 'https://api.suhion.vn',
    image: 'https://api.suhion.vn/data/images/',
    itemPerPage: 10,
  },
  production: {
    api: 'https://api.suhion.vn',
    image: 'https://api.suhion.vn/data/images/',
    itemPerPage: 50,
  },
}

export default WebConfig;