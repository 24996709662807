'use strict';
//var API_URL = 'http://localhost:3004';
import WebConfig from './config';

var API_URL = WebConfig.production.api;
if(window.location.hostname==='localhost'){
  API_URL = WebConfig.development.api;
}

function convertStringToNumber(m) {
  var arr = {
    'Jan': 1,
    'Feb': 2,
    'Mar': 3,
    'Apr': 4,
    'May': 5,
    'Jun': 6,
    'Jul': 7,
    'Aug': 8,
    'Sep': 9,
    'Oct': 10,
    'Nov': 11,
    'Dec': 12
  };
  return arr[m];
}

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN(c = Math.abs(c))
      ? 2
      : c,
    d = d == undefined
      ? "."
      : d,
    t = t == undefined
      ? ","
      : t,
    s = n < 0
      ? "-"
      : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3
      ? j % 3
      : 0;
  return s + (j
    ? i.substr(0, j) + t
    : "") + i
    .substr(j)
    .replace(/(\d{3})(?=\d)/g, "$1" + t) + (c
    ? d + Math.abs(n - i).toFixed(c).slice(2)
    : "");
};


class Utils {
  constructor() {}

  static formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  static isSameDate(date1,date2){
    var y = date1.getFullYear() == date2.getFullYear(),
        m = date1.getMonth() == date2.getMonth(),
        d = date1.getDate() == date2.getDate();
    return y && m && d;
  }

  static getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
       days.push(new Date(date));
       date.setDate(date.getDate() + 1);
    }
    return days;
  }

  static convertJsonToColorString(obj){
    var color = 'rgba('+obj.rgb.r+','+obj.rgb.g+','+obj.rgb.b+','+obj.rgb.a+')';
    return color;
  }

  static encodePassword(pass,callback){
    var encrypted = this.utoa(pass);
    callback(encrypted);
  }

  static utoa(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }
  // base64 encoded ascii to ucs-2 string
  static atou(str) {
      return decodeURIComponent(escape(window.atob(str)));
  }

  static serverAPI(){
    return API_URL;
  }

  static getFileExtension(file){
    var arr = file.split('.'),
        ext = arr[arr.length-1];
    return ext;
  }

  static arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
  }

  static getFriendlyURL(o_url){
    if(typeof o_url !== 'string') return '';
    o_url = o_url.trim();
    o_url = o_url.replace(/(á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ|A|Á|À|Ả|Ã|Ạ|Ă|Ắ|Ằ|Ẳ|Ẵ|Ặ|Â|Ấ|Ầ|Ẩ|Ẫ|Ậ)/g, 'a');
    o_url = o_url.replace(/đ|Đ/g, 'd');
    o_url = o_url.replace(/(é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ|É|È|Ẻ|Ẽ|Ẹ|Ê|Ế|Ề|Ể|Ễ|Ệ)/g, 'e');
    o_url = o_url.replace(/(í|ì|ỉ|ĩ|ị|Í|Ì|Ỉ|Ĩ|Ị)/g, 'i');
    o_url = o_url.replace(/(ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ|Ó|Ò|Ỏ|Õ|Ọ|Ô|Ố|Ồ|Ổ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ở|Ỡ|Ợ)/g, 'o');
    o_url = o_url.replace(/(ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự|Ú|Ù|Ủ|Ũ|Ụ|Ư|Ứ|Ừ|Ử|Ữ|Ự)/g, 'u');
    o_url = o_url.replace(/(ý|ỳ|ỷ|ỹ|ỵ|Ý|Ỳ|Ỷ|Ỹ|Ỵ)/g, 'y');
    o_url = this.replaceAll(o_url,' ','-');
    return o_url.toLowerCase();
  }

  static getBase64(file,callback) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (typeof callback === 'function'){
        callback(reader.result);
      } else {
        return reader.result;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  static replaceAll(str, e, t) {
    var r = str;
    return r.replace(new RegExp(e.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"), t)
  }

  static fixImagePath(image){
    if(process.env.NODE_ENV==='production'){
      var i = image;
      if(image.indexOf(WebConfig.development.image)!==-1){
        i = image.replace(WebConfig.development.image,WebConfig.production.image);
      }else{
        if(image.indexOf('//')==-1){
          i = image = WebConfig.production.api + image;
        }
      }
      //console.log(i);
      return i;
    }else{
      var i = image;
      if(image.indexOf('//')==-1){
          i = image = WebConfig.development.api + image;
        }
      //console.log(i);
      return i;
    }
    return image;
  }

  static getFullDate(date) {
    let y = date.getFullYear(),
      m = (date.getMonth() + 1),
      d = date.getDate();
    if (d < 10)
      d = '0' + d;
    if (m < 10)
      m = '0' + m;
    return y + '-' + m + '-' + d;
  }

  static objectToArray(obj) {
    return Object.keys(obj).map(key => obj[key]);
  }

  static fullProductName(product) {
    let f = product.name,
      p = product.properties;
    var name = f + ' [';
    for (let i = 0, len = p.length; i < len; i++) {
      let n = this.objectToArray(p[i]);
      n.pop();
      //console.log(n);
      name += n.join(':') + ', ';
    }
    name = name.slice(0, -2);
    name += ']';
    return name;
  }

  static formatCurrency(number) {
    return number.formatMoney(0, ',', '.') + ' VNĐ';
  }

  static login(username, password, callback) {
    let url = API_URL + '/api/login/';
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      //console.log(res);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send(JSON.stringify({username:username, password:password}));

  }

  static logout(username, callback) {
    let url = API_URL + '/api/logout/';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      //console.log(res);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send(JSON.stringify({username}));

  }

  static customers(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/customers/',page, pagesize, last_id, filter, forward, callback);
  }

  static customer(id, callback) {
    this.getSingleData('/api/customer/',id,callback);
  }

  static customerbyids(ids, callback) {
    this.getSingleData('/api/customerbyids/',ids,callback);
  }

  static add_customer(obj, callback) {
    this._add('/api/customer/add/',obj,callback);
  }

  static update_customer(obj, callback) {
    this._update('/api/customer/update/',obj, callback);
  }

  static delete_customer(obj, callback) {
    this._delete('/api/customer/delete/',obj,callback);
  }

  static properties(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/properties/',page, pagesize, last_id, filter, forward, callback);
  }

  static property(id, callback) {
    this.getSingleData('/api/property/',id,callback);
  }

  static property_add(obj, callback) {
    this._add('/api/property/add/',obj,callback);
  }

  static property_update(obj, callback) {
    this._update('/api/property/update/',obj, callback);
  }

  static property_delete(obj, callback) {
    this._delete('/api/property/delete/',obj,callback);
  }

  static categories(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/categories/',page, pagesize, last_id, filter, forward, callback);
  }

  static category(id, callback) {
    this.getSingleData('/api/category/',id,callback);
  }

  static category_add(obj, callback) {
    this._add('/api/category/add/',obj,callback);
  }

  static category_update(obj, callback) {
    this._update('/api/category/update/',obj, callback);
  }

  static category_delete(obj, callback) {
    this._delete('/api/category/delete/',obj,callback);
  }

  static news_categories(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/newscats/',page, pagesize, last_id, filter, forward, callback);
  }

  static news_category(id, callback) {
    this.getSingleData('/api/newscat/',id,callback);
  }

  static news_category_add(obj, callback) {
    this._add('/api/newscat/add/',obj,callback);
  }

  static news_category_update(obj, callback) {
    this._update('/api/newscat/update/',obj, callback);
  }

  static news_category_delete(obj, callback) {
    this._delete('/api/newscat/delete/',obj,callback);
  }

  static tags(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/tags/',page, pagesize, last_id, filter, forward, callback);
  }

  static tag(id, callback) {
    this.getSingleData('/api/tag/',id,callback);
  }

  static tag_add(obj, callback) {
    this._add('/api/tag/add/',obj,callback);
  }

  static tag_update(obj, callback) {
    this._update('/api/tag/update/',obj, callback);
  }

  static tag_delete(obj, callback) {
    this._delete('/api/tag/delete/',obj,callback);
  }

  static settings(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/settings/',page, pagesize, last_id, filter, forward, callback);
  }

  static setting(id, callback) {
    this.getSingleData('/api/setting/',id,callback);
  }

  static setting_by_key(key, callback) {
    let url = API_URL + '/api/setting/key/' + key;
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')callback(res);
    };
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static settings_add(obj, callback) {
    this._add('/api/setting/add/',obj,callback);
  }

  static settings_update(obj, callback) {
    this._update('/api/setting/update/',obj, callback);
  }

  static settings_delete(obj, callback) {
    this._delete('/api/setting/delete/',obj,callback);
  }

  static subscribers(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/subscribers/',page, pagesize, last_id, filter, forward, callback);
  }

  static subscriber(id, callback) {
    this.getSingleData('/api/subscriber/',id,callback);
  }

  static subscribers_add(obj, callback) {
    this._add('/api/subscriber/add/',obj,callback);
  }

  static subscribers_update(obj, callback) {
    this._update('/api/subscriber/update/',obj, callback);
  }

  static subscribers_delete(obj, callback) {
    this._delete('/api/subscriber/delete/',obj,callback);
  }

  static campaigns(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/campaigns/',page, pagesize, last_id, filter, forward, callback);
  }

  static campaign(id, callback) {
    this.getSingleData('/api/campaign/',id, callback);
  }

  static campaign_add(obj, callback) {
    this._add('/api/campaign/add/',obj, callback)
  }

  static campaign_update(obj, callback) {
    this._update('/api/campaign/update/',obj, callback);
  }

  static campaign_delete(obj, callback) {
    this._delete('/api/campaign/delete/',obj, callback);
  }

  static products(page, pagesize, last_id, filter, forward, callback) {
    if (filter == '')
      filter = 'null';
    let url = API_URL + '/api/products/' + page + '/' + pagesize + '/' + last_id + '/' + filter + '/' + forward;
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static product(id, callback) {
    this.getSingleData('/api/product/',id,callback);
  }

  static product_add(obj, callback) {
    this._add('/api/product/add/',obj, callback);
  }

  static product_update(obj, callback) {
    this._update('/api/product/update/',obj, callback);
  }

  static product_delete(obj, callback) {
    this._delete('/api/product/delete/',obj,callback);
  }

  static courses(page, pagesize, last_id, filter, forward, callback) {
    if (filter == '')
      filter = 'null';
    this.getListData('/api/courses/',page,pagesize, last_id, filter, forward, callback);
  }

  static course(id, callback) {
    this.getSingleData('/api/course/',id,callback);
  }

  static course_search(keywords, callback) {
    let url = API_URL + '/api/course/search/' + keywords;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        //console.log(res);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        callback({status:'failed',message:xmlhttp.responseText});
      }
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static course_add(obj, callback) {
    this._add('/api/course/add/',obj, callback)
  }

  static course_update(obj, callback) {
    this._update('/api/course/update/',obj, callback);
  }

  static course_delete(obj, callback) {
    this._delete('/api/course/delete/',obj, callback);
  }

  static bills(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/bills/',page, pagesize, last_id, filter, forward, callback);
  }

  static billsrev(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/billsrev/',page, pagesize, last_id, filter, forward, callback);
  }

  static bill(id, callback) {
    this.getSingleData('/api/bill/',id,callback);
  }

  static bill_add(obj, callback) {
    this._add('/api/bill/add/',obj, callback)
  }

  static bill_update(obj, callback) {
    this._update('/api/bill/update/',obj, callback)
  }

  static users(callback) {
    let url = API_URL + '/api/users/';
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      console.log(res);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static user(id, callback) {
    let url = API_URL + '/api/users/' + id;
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      //console.log(res);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static user_add(obj, callback) {
    let url = API_URL + '/api/users/add/';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static user_update(obj, callback) {
    let url = API_URL + '/api/users/update/';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static user_delete(obj, callback) {
    let url = API_URL + '/api/users/delete/';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static user_changepass(obj,callback){
    let url = API_URL + '/api/users/changepass/';

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static groups(callback) {
    let url = API_URL + '/api/groups/';
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      //console.log(res);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static group(id, callback) {
    this.getSingleData('/api/group/',id,callback);
  }

  static group_add(obj, callback) {
    this._add('/api/group/add/',obj, callback);
  }

  static group_update(obj, callback) {
    this._update('/api/group/update/',obj, callback);
  }

  static group_delete(obj, callback) {
    this._delete('/api/group/delete/',obj,callback);
  }

  static faqs(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/faqs/',page, pagesize, last_id, filter, forward, callback);
  }

  static faqsort(direction, sort, page, pagesize, last_id, forward, callback) {
    this.getListDataSort('/api/faqsorder/',direction,sort,page, pagesize, last_id, forward, callback);
  }

  static faq(id, callback) {
    this.getSingleData('/api/faq/',id,callback);
  }

  static faq_add(obj, callback) {
    this._add('/api/faq/add/',obj, callback);
  }

  static faq_update(obj, callback) {
    this._update('/api/faq/update/',obj, callback);
  }

  static faq_delete(obj, callback) {
    this._delete('/api/faq/delete/',obj,callback);
  }

  static faqgroups(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/faqgroups/',page, pagesize, last_id, filter, forward, callback);
  }

  static faqgroupssort(direction, sort, page, pagesize, last_id, forward, callback) {
    this.getListDataSort('/api/faqgroupsorder/',direction,sort,page, pagesize, last_id, forward, callback);
  }

  static faqgroup(id, callback) {
    this.getSingleData('/api/faqgroup/',id,callback);
  }

  static faqgroup_add(obj, callback) {
    this._add('/api/faqgroup/add/',obj, callback);
  }

  static faqgroup_update(obj, callback) {
    this._update('/api/faqgroup/update/',obj, callback);
  }

  static faqgroup_delete(obj, callback) {
    this._delete('/api/faqgroup/delete/',obj,callback);
  }

  static bookings(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/bookings/',page, pagesize, last_id, filter, forward, callback);
  }

  static booking_by_date(start_date, end_date, callback) {
    let url = API_URL + '/api/booking/date/' + start_date + '/' + end_date;
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static booking(id, callback) {
    this.getSingleData('/api/booking/',id,callback);
  }

  static booking_add(obj, callback) {
    this._add('/api/booking/add/',obj, callback);
  }

  static booking_update(obj, callback) {
    this._update('/api/booking/update/',obj, callback);
  }

  static booking_delete(obj, callback) {
    this._delete('/api/booking/delete/',obj,callback);
  }

  static menus(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/menus/',page, pagesize, last_id, filter, forward, callback);
  }

  static menu(id, callback) {
    this.getSingleData('/api/menu/',id,callback);
  }

  static menu_add(obj, callback) {
    this._add('/api/menu/add/',obj, callback);
  }

  static menu_update(obj, callback) {
    this._update('/api/menu/update/',obj, callback);
  }

  static menu_delete(obj, callback) {
    this._delete('/api/menu/delete/',obj,callback);
  }

  static news(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/news/',page, pagesize, last_id, filter, forward, callback);
  }

  static new(id, callback) {
    this.getSingleData('/api/new/',id,callback);
  }

  static news_add(obj, callback) {
    this._add('/api/new/add/',obj,callback);
  }

  static news_update(obj, callback) {
    this._update('/api/new/update/',obj, callback);
  }

  static news_delete(obj, callback) {
    this._delete('/api/new/delete/',obj,callback);
  }

  static contentpages(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/contentpages/',page, pagesize, last_id, filter, forward, callback);
  }

  static contentpage(id, callback) {
    this.getSingleData('/api/contentpage/',id,callback);
  }

  static contentpage_add(obj, callback) {
    this._add('/api/contentpage/add/',obj,callback);
  }

  static contentpage_update(obj, callback) {
    this._update('/api/contentpage/update/',obj, callback);
  }

  static contentpage_delete(obj, callback) {
    this._delete('/api/contentpage/delete/',obj,callback);
  }

  static banners(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/banners/',page, pagesize, last_id, filter, forward, callback);
  }

  static banner(id, callback) {
    this.getSingleData('/api/banner/',id,callback);
  }

  static banner_add(obj, callback) {
    this._add('/api/banner/add/',obj,callback);
  }

  static banner_update(obj, callback) {
    this._update('/api/banner/update/',obj, callback);
  }

  static banner_delete(obj, callback) {
    this._delete('/api/banner/delete/',obj,callback);
  }

  static documents(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/documents/',page, pagesize, last_id, filter, forward, callback);
  }

  static document(id, callback) {
    this.getSingleData('/api/document/',id,callback);
  }

  static document_add(obj, callback) {
    this._add('/api/document/add/',obj,callback);
  }

  static document_update(obj, callback) {
    this._update('/api/document/update/',obj, callback);
  }

  static document_delete(obj, callback) {
    this._delete('/api/document/delete/',obj,callback);
  }

  static schedules(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/schedules/',page, pagesize, last_id, filter, forward, callback);
  }

  static schedule(id, callback) {
    this.getSingleData('/api/schedule/',id,callback);
  }

  static schedulebycourse(id, callback) {
    let url = API_URL + '/api/searchschedulebycourse/' + id;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        //console.log(res);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        callback({status:'failed',message:xmlhttp.responseText});
      }
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static schedule_add(obj, callback) {
    this._add('/api/schedule/add/',obj,callback);
  }

  static schedule_update(obj, callback) {
    this._update('/api/schedule/update/',obj, callback);
  }

  static schedule_delete(obj, callback) {
    this._delete('/api/schedule/delete/',obj,callback);
  }

  static schedule_search(year, month, callback) {
    return '';
  }

  static lecturers(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/lecturers/',page, pagesize, last_id, filter, forward, callback);
  }

  static lecturer_search(keywords, callback) {
    let url = API_URL + '/api/lecturer/search/' + keywords;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        //console.log(res);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        callback({status:'failed',message:xmlhttp.responseText});
      }
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static lecturer(id, callback) {
    this.getSingleData('/api/lecturer/',id, callback);
  }

  static lecturer_add(obj, callback) {
    this._add('/api/lecturer/add/',obj, callback)
  }

  static lecturer_update(obj, callback) {
    this._update('/api/lecturer/update/',obj, callback);
  }

  static lecturer_delete(obj, callback) {
    this._delete('/api/lecturer/delete/',obj, callback);
  }

  static albums(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/albums/',page, pagesize, last_id, filter, forward, callback);
  }

  static album(id, callback) {
    this.getSingleData('/api/album/',id, callback);
  }

  static album_add(obj, callback) {
    this._add('/api/album/add/',obj, callback)
  }

  static album_update(obj, callback) {
    this._update('/api/album/update/',obj, callback);
  }

  static album_delete(obj, callback) {
    this._delete('/api/album/delete/',obj, callback);
  }

  static services(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/services/',page, pagesize, last_id, filter, forward, callback);
  }

  static service(id, callback) {
    this.getSingleData('/api/service/',id, callback);
  }

  static service_add(obj, callback) {
    this._add('/api/service/add/',obj, callback)
  }

  static service_update(obj, callback) {
    this._update('/api/service/update/',obj, callback);
  }

  static service_delete(obj, callback) {
    this._delete('/api/service/delete/',obj, callback);
  }

  static testimonials(page, pagesize, last_id, filter, forward, callback) {
    this.getListData('/api/testimonials/',page, pagesize, last_id, filter, forward, callback);
  }

  static testimonial(id, callback) {
    this.getSingleData('/api/testimonial/',id, callback);
  }

  static testimonial_add(obj, callback) {
    this._add('/api/testimonial/add/',obj, callback)
  }

  static testimonial_update(obj, callback) {
    this._update('/api/testimonial/update/',obj, callback);
  }

  static testimonial_delete(obj, callback) {
    this._delete('/api/testimonial/delete/',obj, callback);
  }

  static createFolder(folder,callback) {
    var obj = {folder:folder};
    let url = API_URL + '/api/folder/create/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static sendMail(form,callback){
    var obj = {};
    obj.email = form.email;
    obj.emailsubject = form.emailsubject;
    obj.name = form.fullname;
    obj.content = form.emailbody;
    obj.sendto = form.sendto;

    let url = API_URL + '/api/sendemail/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if(typeof callback == 'function') callback(res);
    }
    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static upload(file,type,ext,success,error){
    var obj = {file:file,type:type,ext:ext};
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', API_URL + '/api/upload');
    xmlhttp.addEventListener('load', () => {
        const response = JSON.parse(xmlhttp.responseText);
        success({data: {link: response.photoURL}})
    });
    xmlhttp.addEventListener('error', () => {
        const error = JSON.parse(xmlhttp.responseText);
        console.log(error);
        error(error);
    });
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static delete(file,callback){
    var obj = {file:file};
    
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", API_URL + '/api/mediadelete');
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static getThumbnail(file,size,callback){
    let url = API_URL + '/api/getimage/' + file + '?size='+size.width+'x'+size.height;
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = xmlhttp.responseText;
      if (typeof callback === 'function')
        callback(res);
      };
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static getFiles(folder,callback){
    let url = API_URL + '/api/media/' + encodeURIComponent(folder);
    if(folder===''||folder==='/')url = API_URL + '/api/mediaroot/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };
    let obj = {folder: folder}
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static getAllMedia(callback){
    let url = API_URL + '/api/mediaall/';
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };
    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send();
  }

  static getListData(api,page, pagesize, last_id, filter, forward, callback) {
    if (filter === '')filter = 'null';
    
    let url = API_URL + api + page + '/' + pagesize + '/' + last_id + '/' + filter + '/' + forward;
    //console.log(url);
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      //console.log(xmlhttp.responseText);
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static getListDataSort(api, direction, sort, page, pagesize, last_id, forward, callback) {
    
    let url = API_URL + api + direction + '/' + sort + '/' + page + '/' + pagesize + '/' + last_id + '/' + forward;
    console.log(url);
    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      //console.log(xmlhttp.responseText);
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static getSingleData(api,id, callback) {
    let url = API_URL + api + id;
    //let jwt = localStorage.getItem('api_token');

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      try {
        let res = JSON.parse(xmlhttp.responseText);
        //console.log(res);
        if (typeof callback === 'function')callback(res);
      } catch (error) {
        callback({status:'failed',message:xmlhttp.responseText});
      }
    };

    xmlhttp.open("GET", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    //xmlhttp.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xmlhttp.send();
  }

  static _add(api,obj, callback) {
    let url = API_URL + api;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static _update(api,obj, callback) {
    let url = API_URL + api;

    let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
    xmlhttp.onload = function(e) {
      let res = JSON.parse(xmlhttp.responseText);
      if (typeof callback === 'function')
        callback(res);
      };

    xmlhttp.open("POST", url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json');
    xmlhttp.send(JSON.stringify(obj));
  }

  static _delete(api,obj, callback) {
    var i = window.confirm('Please confirm you want to delete this item, it can\'t rollback!!!');
    if(i){
      let url = API_URL + api;

      let xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
      xmlhttp.onload = function(e) {
        let res = JSON.parse(xmlhttp.responseText);
        if (typeof callback === 'function')
          callback(res);
        };

      xmlhttp.open("POST", url);
      xmlhttp.setRequestHeader('Content-Type', 'application/json');
      xmlhttp.send(JSON.stringify(obj));
    }else{
      return false;
    }
  }

  static setupEditor($,folder){
    $.FroalaEditor.DefineIcon('cmsStyle', {NAME: 'cog'});
    $.FroalaEditor.DefineIcon('cmsTemplate', {NAME: 'server'});
    $.FroalaEditor.RegisterCommand('cmsStyle', {
      title: 'Suhion styles',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: {
        'gap': 'Insert a gap',
        'gap half': 'Insert a half gap',
        'container': 'Warp content by a container',
        'section': 'Insert a section',
        'section-heading': 'Apply style heading',
        'section-break': 'Insert suhion break line',
        'button-primary': 'Insert a primary style button',
        'button-white': 'Insert a white style button',
        'button-border-white': 'Insert a border style button'
      },
      callback: function (cmd, val) {
        var editor = this;
        switch(val){
          case 'container':
            var html = editor.html.getSelected();
            editor.html.insert('<div class="container">'+html+'</div>',true);
            //editor.html.wrap(false,false,false);
            //editor.paragraphStyle.apply('container');
            break;
          case 'section':
            var html = editor.html.getSelected();
            editor.html.insert('<section>'+html+'</section>',true);
            //editor.html.wrap(false,false,false);
            //editor.paragraphStyle.apply('container');
            break;
          case 'gap':
            editor.html.insert('<div class="gap"></div>',true);
            break;
          case 'gap-half':
            editor.html.insert('<div class="gap half"></div>',true);
            break;
          case 'section-heading':
            var html = editor.html.getSelected();
            editor.html.insert('<h2 class="heading">'+html+'</h2>',true);
            break;
          case 'section-break':
            editor.html.insert('<div class="line-break"></div>',false);
            break;
          case 'button-primary':
            editor.html.insert('<a class="btn btn-primary" href="#" title="Edit text and Link">Edit text and Link</a>',true);
            break;
          case 'button-white':
            editor.html.insert('<a class="btn btn-white" href="#" title="Edit text and Link">Edit text and Link</a>',true);
            break;
          case 'button-border-white':
            editor.html.insert('<a class="btn btn-border-white" href="#" title="Edit text and Link">Edit text and Link</a>',true);
            break;
          default:
            return true;
        }
      },
      // Callback on refresh.
      refresh: function ($btn) {
        console.log ('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        console.log ('do refresh when show');
      }
    });

    $.FroalaEditor.RegisterCommand('cmsTemplate', {
      title: 'Suhion template',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: {
        'section': 'Insert section template',
        'bannerb': 'Insert static banner - Black text',
        'bannerw': 'Insert static banner - White text',
        'lecture': 'Insert lecture template',
        'h-tabs': 'Insert top-down tab',
        'v-tabs': 'Insert left-right tab',
      },
      callback: function (cmd, val) {
        var editor = this;
        switch(val){
          case 'bannerb':
            var str = '<div class="static-banner dark" style="background:url('+ API_URL +'/data/images/no_photo.png) center no-repeat;background-size:cover;height:180px;"><h2 class="banner-title">Page Title</h2><div class="banner-links"><a href="/vn/" title="Title 1">Title 1</a>|<a href="/vn/" title="Title 2">Title 2</a></div></div>';
            editor.html.insert(str,true);
            break;
          case 'bannerw':
            var str = '<div class="static-banner" style="background:url('+ API_URL +'/data/images/no_photo.png) center no-repeat;background-size:cover;height:180px;"><h2 class="banner-title">Page Title</h2><div class="banner-links"><a href="/vn/" title="Title 1">Title 1</a>|<a href="/vn/" title="Title 2">Title 2</a></div></div>';
            editor.html.insert(str,true);
            break;
          case 'section':
            var str = '<section><div class="container"><div class="gap">&nbsp;</div><h2 class="heading">Section heading ...</h2><div class="line-break">&nbsp;</div><div class="desc"><p>Lorem ipsum dolor sit amet, omnis blandit persequeris an quo, exerci nostrud euripidis ea vel. His an aliquid facilisi, mea te eripuit necessitatibus, ne cum epicuri necessitatibus.</p></div><div class="content"><div class="text">Lorem ipsum dolor sit amet, omnis blandit persequeris an quo, exerci nostrud euripidis ea vel. His an aliquid facilisi, mea te eripuit necessitatibus, ne cum epicuri necessitatibus.</div></div><div class="gap">&nbsp;</div></div></section>';
            editor.html.insert(str,true);
            break;
          case 'lecture':
            var str = '<p class="lecture-summary"><i class="fa fa-star"></i> Lorem ipsum dolor sit amet, vero melius vulputate ei sit, no dolorem similique nam</p>';
            editor.html.insert(str,true);
            break;
          case 'h-tabs':
            var str = '<div class="horizontal-tabs-wrapper">';
                str += '<ul class="nav nav-horizontal nav-accordion">';
                str += '<li class="nav-item"><a class="nav-link active" data-toggle="tab-accordion" href="#item1">Item title 1</a></li>';
                str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item2">Item title 2</a></li>';
                str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item3">Item title 3</a></li>';
                str += '</ul><div class="tab-content"><div class="tab-pane active" id="item1">Content of item 1</div><div class="tab-pane" id="item2">Content of item 2</div><div class="tab-pane" id="item3">Content of item 3</div></div></div>';
            editor.html.insert(str,true);
            break;
          case 'v-tabs':
            var str = '<div class="vertical-tabs-wrapper">';
                str += '<ul class="nav nav-vertical nav-accordion">';
                str += '<li class="nav-item"><a class="nav-link active" data-toggle="tab-accordion" href="#item1">Item title 1</a></li>';
                str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item2">Item title 2</a></li>';
                str += '<li class="nav-item"><a class="nav-link" data-toggle="tab-accordion" href="#item3">Item title 3</a></li>';
                str += '</ul><div class="tab-content"><div class="tab-pane active" id="item1">Content of item 1</div><div class="tab-pane" id="item2">Content of item 2</div><div class="tab-pane" id="item3">Content of item 3</div></div></div>';
            editor.html.insert(str,true);
            break;
          default:
            return true;
        }
      },
      // Callback on refresh.
      refresh: function ($btn) {
        console.log ('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        console.log ('do refresh when show');
      }
    });

    const editorConfig = {
      placeholderText: '...',
      imageUploadURL: Utils.serverAPI() + '/api/upload/' + folder,
      imageUploadMethod: 'POST',
      imageMaxSize: 5 * 1024 * 1024,
      imageAllowedTypes: ['jpeg', 'jpg', 'png','gif'],
      imageManagerLoadURL: Utils.serverAPI() + '/api/mediaall',
      height: 500,
      htmlRemoveTags: ['script', 'base'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
      toolbarButtons: ['fullscreen','bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertFile', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'spellChecker', 'help', 'cmsStyle', 'cmsTemplate', 'html', '|', 'undo', 'redo'],
      codeMirrorOptions: {
        theme: 'material',
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 4
      }
    }
    return editorConfig;
  }

  static setupBasicEditor(){
    const editorConfigBasic = {
      placeholderText: '...',
      toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'undo', 'redo', '|', 'html'],
      height: 200,
      codeMirrorOptions: {
        theme: 'material',
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 4
      }
    }
    return editorConfigBasic;
  }

  static getBeautyDate(date, locale) {
    var str = (new Date(date)).toDateString();
    var arr = str.split(' ');
    switch (locale.toLowerCase()) {
      case 'vn':
        return arr[2] + ' tháng ' + convertStringToNumber(arr[1]) + ' năm ' + arr[3];
        break;
      case 'kr':
        return arr[3] + ' 년 ' + convertStringToNumber(arr[1]) + ' 월 ' + arr[2] + ' 일';
        break;
      default:
        return arr[1] + ' ' + arr[2] + ' ' + arr[3];
        break;
    }
  }

  static variantExist(a,b){
    var isDuplicate = true;
    var a_v = a.values,
        b_v = b.values;
    for(var property in a_v) {
      if(property!=='price'&&property!=='status'){
        if(a_v[property]!==b_v[property]){
          isDuplicate = false;
          break;
        }
      }
    }
    return isDuplicate;
  }

}

export default Utils;
