import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar,Toolbar, Button,IconButton,Typography} from 'material-ui';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import MenuIcon from 'material-ui-icons/Menu';
import {styles} from '../layout/theme';
import Utils from '../api/api';
import {Link} from 'react-router-dom';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      width:  960
    }
  }
  handleToggle() {
    this.props.toggleMenu();
  }
  handleLogout(){
    //console.log(this.state);
    var $this = this;
    Utils.logout(this.state.username,function(data){
      //console.log(data);
      if(data.message==='success'){
        localStorage.removeItem('user_token');
        localStorage.removeItem('user');
        localStorage.removeItem('userg');
        //console.log($this.context.router);
        $this.props.updateLoggedStatus();
        $this.context.router.history.push('/login');
      }else{
        alert(data.message);
      }
    });
  }
  componentDidMount(){
    //console.log(JSON.parse(this.props.user));
  }
  render(){
    const { classes } = this.props;
    //var type = this.props.screenWidth<960 ? false: true;
    return(
      <AppBar className={'header ' + classNames(classes.appBar, ((this.props.open)&&this.props.isLoggedIn)  && classes.appBarShift)}>
        <Toolbar>
          <IconButton aria-label="Menu" onClick={()=>this.handleToggle()} color="inherit" className={classNames(classes.menuButton, (this.props.open)  && classes.hide)}>
            <MenuIcon/>
          </IconButton>
          <Typography variant="title" color="inherit" className={classNames(classes.flex,classes.siteTitle)}>{this.props.title}</Typography>
          {
            this.props.isLoggedIn && (
              <Typography variant="caption" color="inherit" className={classes.flex}>Welcome,
                <Link className={classes.headingUsername} to='/changepassword'> {this.props.user.title}</Link>
              </Typography>
            )
          }
          <Button color="inherit" onClick={()=>this.handleLogout()} className={classNames('', !this.props.isLoggedIn  && classes.hide)}>Logout</Button>
        </Toolbar>
      </AppBar>
    );
  }
}


Header.propTypes = {
  classes: PropTypes.object.isRequired,
};
Header.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(Header);
