import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';

// import Banner from '../components/banner';
// import Bill from '../components/bill';
// import Customer from '../components/customer';
// import Dashboard from '../components/dashboard';
// import ReactPressProperty from '../components/property';
// import Product from '../components/product';
// import Course from '../components/course';
// import Category from '../components/category';
// import FAQs from '../components/faq';
// import News from '../components/news';
// import ContentPage from '../components/content_page';
// import Users from '../components/users';
// import Group from '../components/groups';
// import Login from '../components/login';
// import MediaLibrary from '../components/media';
// import WebMenu from '../components/web_menu';
// import Service from '../components/service';
// import Album from '../components/album';
// import Testimonial from '../components/testimonial';
// import CategoryNews from '../components/category_news';
// import Settings from '../components/settings';
// import Permission from '../components/permission';
// import ChangePassword from '../components/change_password';
// import SubscribesList from '../components/subscribe';
// import Campaign from '../components/campaign';
// import ReactPressFile from '../components/files';
// import Schedule from '../components/schedule';
// import Lecturer from '../components/lecturer';
// import CategoryFAQ from '../components/category_faq'; 
// import Booking from '../components/booking';

import Utils from '../api/api';

import theme from './theme';

import globalstyles from '../assets/css/style.css';

import Loadable from 'react-loadable';

const Loading = () => <div>Loading...</div>;

const Login = Loadable({
  loader: () => import('../components/login'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('../components/dashboard'),
  loading: Loading,
});

const Banner = Loadable({
  loader: () => import('../components/banner'),
  loading: Loading,
});

const Bill = Loadable({
  loader: () => import('../components/bill'),
  loading: Loading,
});

const Customer = Loadable({
  loader: () => import('../components/customer'),
  loading: Loading,
});

const ReactPressProperty = Loadable({
  loader: () => import('../components/property'),
  loading: Loading,
});

const Product = Loadable({
  loader: () => import('../components/product'),
  loading: Loading,
});

const Course = Loadable({
  loader: () => import('../components/course'),
  loading: Loading,
});

const Category = Loadable({
  loader: () => import('../components/category'),
  loading: Loading,
});

const FAQs = Loadable({
  loader: () => import('../components/faq'),
  loading: Loading,
});

const News = Loadable({
  loader: () => import('../components/news'),
  loading: Loading,
});

const ContentPage = Loadable({
  loader: () => import('../components/content_page'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('../components/users'),
  loading: Loading,
});

const Group = Loadable({
  loader: () => import('../components/groups'),
  loading: Loading,
});

const MediaLibrary = Loadable({
  loader: () => import('../components/media'),
  loading: Loading,
});

const WebMenu = Loadable({
  loader: () => import('../components/web_menu'),
  loading: Loading,
});

const Service = Loadable({
  loader: () => import('../components/service'),
  loading: Loading,
});

const Album = Loadable({
  loader: () => import('../components/album'),
  loading: Loading,
});

const Testimonial = Loadable({
  loader: () => import('../components/testimonial'),
  loading: Loading,
});

const CategoryNews = Loadable({
  loader: () => import('../components/category_news'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('../components/settings'),
  loading: Loading,
});

const Permission = Loadable({
  loader: () => import('../components/permission'),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import('../components/change_password'),
  loading: Loading,
});

const SubscribesList = Loadable({
  loader: () => import('../components/subscribe'),
  loading: Loading,
});

const Campaign = Loadable({
  loader: () => import('../components/campaign'),
  loading: Loading,
});

const ReactPressFile = Loadable({
  loader: () => import('../components/files'),
  loading: Loading,
});

const Schedule = Loadable({
  loader: () => import('../components/schedule'),
  loading: Loading,
});

const Lecturer = Loadable({
  loader: () => import('../components/lecturer'),
  loading: Loading,
});

const CategoryFAQ = Loadable({
  loader: () => import('../components/category_faq'),
  loading: Loading,
});

const Booking = Loadable({
  loader: () => import('../components/booking'),
  loading: Loading,
});

const drawerWidth = 240;
const styles = {
  content: {
    width: 'calc(100% - 240px)',
    overflowX:'hidden',
    marginLeft: -drawerWidth,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 'calc(100% - 56px)',
    marginTop: 56,
    position:'relative'
  },
  [theme.breakpoints.up('sm')]: {
    content: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
      padding: theme.spacing.unit * 3,
    },
  },
  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
};



class Main extends Component {
  constructor(props){
    super(props);
    this.state = {
      group: '',
      groups: []
    }
  }
  componentDidMount(){
    var $this = this;
    if(!this.props.isLoggedIn&&this.context.router.route.location.pathname.indexOf('/login')===-1){
      this.context.router.history.push('/login');
      //console.log(this.context);
    }else {
      var g = JSON.parse(localStorage.getItem('userg'));
      Utils.groups(function(data){
        $this.setState({groups:data.results},function(){
          if(g!==null){
            g = Utils.atou(g.group);
            //console.log(g);
            var gn = this.state.groups.filter(x=>{
              return x._id === g;
            });
            //console.log(this.state.groups);
            this.setState({group:gn[0].name},function(){
              //console.log($this.state.group);
            });
          }
        });
      });
    }
  }
  updateStatusLogin(cb){
    try {
      var g = JSON.parse(localStorage.getItem('userg'));
      if(g!==null){
        g = Utils.atou(g.group);
        // console.log(g);
        // console.log(this.state.groups);
        var gn = this.state.groups.filter(x=>{
          return x._id === g;
        });
        if(gn.length>0){
          this.setState({group:gn[0].name},function(){
            //console.log($this.state.group);
            this.props.updateLoggedStatus();
            cb();
          });
        }else{
          this.props.updateLoggedStatus();
          cb();
        }
      }
      //console.log(cb);
      
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    //console.log(this.context.router);
    const { classes } = this.props;
    //console.log(this.state.group);

    //var type = this.props.screenWidth<960 ? false: true;
    return (
      <div className={'main ' + classNames(classes.content, (this.props.open) && classes.contentShift)}>
        <Switch>
          <Route exact path='/' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:dashboard" />) : (<Dashboard/>))}/>
          <Route exact path='/media/:folder?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:media" />) : (<MediaLibrary/>))}/>
          <Route path='/login/:url?' render={()=>(<Login isLoggedIn={this.props.isLoggedIn} updateStatus={(cb)=>this.updateStatusLogin(cb)}/>)}/>
          <Route path='/changepassword' render={()=>(<ChangePassword/>)}/>
          <Route path='/bill/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:bill" />) : (<Bill/>))} />
          <Route path='/customer/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:customer" />) : (<Customer/>))}/>
          <Route path='/property/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:property" />) : (<ReactPressProperty/>))}/>
          <Route path='/product/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:product" />) : (<Product/>))}/>
          <Route path='/course/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:course" />) : (<Course/>))}/>
          <Route path='/category/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:category" />) : (<Category/>))}/>
          <Route path='/banner/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:banner" />) : (<Banner/>))}/>
          <Route path='/menu/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:menu" />) : (<WebMenu/>))}/>
          <Route path='/news/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:news" />) : (<News/>))}/>
          <Route path='/pages/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:pages" />) : (<ContentPage/>))}/>
          <Route path='/faq/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:faq" />) : (<FAQs/>))}/>
          <Route path='/users/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:users" />) : (<Users group={this.state.group}/>))}/>
          <Route path='/group/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:group" />) : (<Group group={this.state.group}/>))}/>
          <Route path='/service/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:service" />) : (<Service/>))}/>
          <Route path='/album/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:album" />) : (<Album/>))}/>
          <Route path='/testimonial/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:testimonial" />) : (<Testimonial/>))}/>
          <Route path='/newscat/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:newscat" />) : (<CategoryNews/>))}/>
          <Route path='/setting/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:setting" />) : (<Settings/>))}/>
          <Route path='/permission/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:permission" />) : (<Permission group={this.state.group}/>))}/>
          <Route path='/subscribes/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:subscribes" />) : (<SubscribesList/>))}/>
          <Route path='/campaign/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:campaign" />) : (<Campaign/>))}/>
          <Route path='/document/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:document" />) : (<ReactPressFile/>))}/>
          <Route path='/calendar/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:calendar" />) : (<Schedule/>))}/>
          <Route path='/lecturers/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:lecturers" />) : (<Lecturer/>))}/>
          <Route path='/faqgroup/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:faqgroup" />) : (<CategoryFAQ/>))}/>
          <Route path='/booking/:action?/:id?' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:booking" />) : (<Booking/>))}/>
        </Switch>
      </div>
    );
  }
}
Main.propTypes = {
  classes: PropTypes.object.isRequired,
  //router: PropTypes.object
};
Main.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(Main);
